import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      'en-US': {
        translation: {
          cited_home_title: 'Cited Professsional Sources',
          cited_home_content:
            'The data source of our model is derived from thousands of professional medicine textbooks and advanced medical articles. Rest assured, we can provide cited sources for every knowledge point we present to you.',
          nih_home_title: 'PubMed Articles Search',
          nih_home_content:
            'Our model offers advanced search capabilities for PubMed articles and can provide the most relevant PubMed articles related to the diseases or medicines you wish to find.',
          veterinary_home_title: 'Veterinary Medicals',
          veterinary_home_content:
            'In addition to human medical knowledge, our medical assistant can also offer professional veterinary treatment plans, drawing from an extensive database of over 2000+ veterinary books and 10000+ online veterinary articles.',
          home_sub_title:
            'Get instant human/veterinary medical answers for treatment, patient education and drugs',
          home_title: 'Medical Chat',
          home_title_2:
            'Advanced AI Assistant For <0>{{target}}</0> Healthcare',
          price: 'Pricing',
          chatbot: 'Medical Chatbot',
          diagnose_btn: 'AI Diagnose',
          side_by_side: 'Side by Side',
          community_btn: 'Community',
          copyright: '{{date}} © Medical Chat | All rights reserved.',
          footer_disclaimer: `Disclaimer: Our website offers AI services in the area of medication, but please note that the responses generated by the model are based on the information available at the time of its training and may not always be accurate, complete, or up-to-date. The responses are provided for informational purposes only and are intended to assist doctors with their daily diagnostic work, rather than to provide medical consultations to patients. Always consult a qualified healthcare provider before making any decisions related to your health or well-being. We do not assume any liability for any loss, injury, or damage resulting from the use of our services or the information provided by the model. By using our services, you agree to indemnify and hold us harmless from any and all claims, demands, and damages, including attorneys' fees, arising out of or in connection with your use of our services. 
                    We also do not curate, endorse, represent, support or guarantee the accuracy, completeness or reliability of any content or communications posted in the community library. In no event shall Medical Chat be liable for any loss or damage including without limitation, indirect or consequential loss or damage from using this website."`,
          locale_all: 'All',
          locale_en: 'English',
          locale_cn: 'Chinese',
          contact_email_msg_prefix: 'For help and support shoot us an',
          contact_email_msg_suffix: ' email',
          footer_privacy: 'Privacy Policy',
          footer_service: 'Terms of Service',
          Specialty: 'Specialty',
          'Patient Education': ' Patient Education',
          'Drugs Information': ' Drugs',
          chat_footer: 'Advanced AI Assistant For Human/Veterinary Healthcare',
          chat_input_placehoder:
            'What are the symptoms of Cardiovascular Disorders?',
          chat_vet_input_placehoder:
            'What are the recommended treatment options for managing feline diabetes mellitus?',
          'Example: Specialty':
            'Example questions for Specialty <0>{{nouns_warning}}</0>',
          'Example: Veterinary':
            'Example questions for Veterinary<0>{{nouns_warning}}</0>',
          nouns_warning: '(Please use English for proper nouns)',
          specialty_example_question_1:
            'What is carbidopa-levodopa enteral suspension and what is it used for?',
          specialty_example_question_2:
            'What are the potential risks or side effects associated with using LCIG?',
          specialty_example_question_3:
            'What is the difference between a self-antigen and a foreign antigen?',
          specialty_example_question_4:
            'What are some common treatments for autoimmune diseases?',
          specialty_example_question_5:
            'What is an autoimmune disease and how is it different from other immune responses?',
          specialty_example_question_6:
            'What are some mechanical factors that can contribute to back pain during pregnancy?',
          specialty_example_question_7:
            'Can back pain during pregnancy be prevented?',
          specialty_example_question_8:
            'What are the complications of septic thrombophlebitis?',
          specialty_example_question_9:
            'How long should antibiotic therapy be continued for catheter-related septic thrombophlebitis?',
          specialty_example_question_10:
            'What are the most common activities that result in knee injuries in children and adolescents?',
          specialty_example_question_11:
            'What diagnostic tools are used to diagnose acute knee injuries?',
          specialty_example_question_12:
            'Is intermittent skin itching a sign of illness? What can be done to stop it?',
          vet_example_question_1:
            'What are the recommended treatment options for feline diabetes mellitus?',
          vet_example_question_2:
            'What medication or treatments are commonly used to alleviate equine colic?',
          vet_example_question_3:
            'What is the most effective treatment protocol for canine heartworm disease?',
          vet_example_question_4:
            'How does veterinary acupuncture aid in the treatment of musculoskeletal conditions in dogs?',
          vet_example_question_5:
            'What is the recommended dosage and administration of Respireze for birds with chronic respiratory infections?',
          vet_example_question_6:
            'Are there any drug interactions or contraindications to consider when prescribing HemoVet to ferrets with anemia?',
          vet_example_question_7:
            'How does GastroGuard Plus treat gastric ulcers in racehorses, and what are its specific therapeutic targets?',
          vet_example_question_8:
            'What is the recommended dosage and administration of RenalCare for dogs with chronic kidney disease?',
          vet_example_question_9:
            'What are the essential vaccinations and preventive medications recommended for puppies, and at what age should they begin their vaccination schedule?',
          vet_example_question_10:
            'What are the signs of dental disease in guinea pigs, and how can their dental health be supported through appropriate diet and chewing materials?',
          vet_example_question_11:
            'What are the important considerations for creating a safe outdoor space for pet tortoises, including enclosure design, sun exposure, and protection from predators?',
          vet_example_question_12:
            'What are the appropriate steps for introducing a new kitten to an existing household with resident cats, and how can a smooth transition be facilitated?',
          'Example: Patient Education':
            'Example questions for Patient Education',
          education_example_question_1: 'What is a bone marrow transplant?',
          education_example_question_2: 'How do I find a stem cell donor?',
          education_example_question_3:
            'What are the symptoms of hairy cell leukemia?',
          education_example_question_4: 'How is hairy cell leukemia treated?',
          education_example_question_5:
            'I have occasional headache, what could be the reason?',
          education_example_question_6:
            'What are the symptoms of sickle cell trait?',
          education_example_question_7: 'What are the symptoms of insomnia?',
          education_example_question_8: 'How is insomnia treated?',
          education_example_question_9:
            'Can not getting enough sleep lead to problems?',
          education_example_question_10: 'What causes adrenal crisis?',
          education_example_question_11: 'Can adrenal crisis be prevented?',
          education_example_question_12:
            'Is there a test for hepatic encephalopathy?',
          'Example: Drugs Information':
            'Example questions for Drugs Information',
          drugs_example_question_1:
            'What is Abaloparatide and what are its possible side effects?',
          drugs_example_question_2:
            'What are the possible local reactions that may occur at the site of injection of Abaloparatide?',
          drugs_example_question_3:
            'What monitoring parameters should be evaluated when using abaloparatide?',
          drugs_example_question_4:
            'What are some common adverse reactions associated with acarbose?',
          drugs_example_question_5:
            'Why is acarbose contraindicated for patients with inflammatory bowel disease and colonic ulceration?',
          drugs_example_question_6:
            'What kind of conditions may deteriorate as a result of increased gas formation in the intestine caused by acarbose?',
          drugs_example_question_7: 'How may Digoxin be affected by Acarbose?',
          drugs_example_question_8:
            'How is Abemaciclib administered and what are the recommended precautions to take while handling it?',
          drugs_example_question_9:
            'What are the most common adverse reactions associated with Abemaciclib and what are their incidence rates?',
          drugs_example_question_10:
            'What are the renal adverse reactions associated with Abemaciclib?',
          drugs_example_question_11:
            'Are there any contraindications to the use of Abemaciclib, and if so, what are they?',
          drugs_example_question_12:
            'Is Acetazolamide contraindicated in patients with severe kidney impairment?',
          limitation: 'Limitations',
          limitation_1:
            'The information is intended to serve doctors and assist with their daily diagnostic work, rather than being targeted towards patients.',
          limitation_2:
            'The information should be used as a tool to help the user understand potential diagnostic and treatment options.',
          limitation_3:
            'The information may not cover all details about conditions, treatments, medications, side effects, or risks specific to a patient.',
          limitation_4:
            "It is not intended to replace medical advice, diagnosis, or treatment from a healthcare provider who has examined and assessed the patient's unique circumstances.",
          limitation_5:
            'Patients must consult with a healthcare provider to receive complete information about their health, medical inquiries, and treatment options, including any risks or benefits of using medications.',
          limitation_6:
            "The information provided does not support any treatments or medications as being safe, effective, or approved for a particular patient's treatment.",
          chat_expand: 'More',
          chat_ref: 'PubMed Library',
          sign_up_title: 'Customer Registration',
          user_name: 'username',
          email: 'email',
          password: 'password',
          'confirm password': 'confirm password',
          submit: 'SUBMIT',
          ack: 'Got it',
          homescreen_share_hint:
            'Add to your homescreen for an app-like experience',
          sign_in_reminder: 'Already registered?',
          sign_in: 'Sign in',
          sign_in_failure: 'Signin failed',
          sign_up: 'Sign up',
          sign_out: 'Sign out',
          password_different: "Passwords don't match",
          password_restriction:
            'Your password has to contain at least 6 characters',
          sign_in_title: 'User Sign in',
          forget_password_reminder: 'Forget password?',
          sign_up_reminder: 'Need account?',
          reset_password_title: 'Reset password',
          reset_password_sent:
            'The password reset has been sent, please check your email! (It will expire after 24 hours)',
          reset_password_failure: 'The password reset failed.',
          reset_password_success:
            'Password reset successful, please log in again',
          nav_profile: 'Profile',
          loading_msg: 'Loading...',
          setting_title: 'Profile',
          setting_sub_title: 'Change Profile',
          current_password: 'Current password',
          new_password: 'New password',
          new_password_confirm: 'Confirm new password',
          refer_link: 'Refer link',
          setting_password_placeholder: 'Leave blank to keep it unchanged',
          current_password_placeholder:
            'Current password is required to change the password',
          verify_email: 'Verify email',
          email_verification_success: 'Email verification successful',
          email_verification_failure: 'Email verification failed',
          code_type_wrong: 'Incorrect verification code type',
          others_code: 'The verification code belongs to another user',
          email_sent_success: 'Email is sent',
          email_sent_failure: 'Failed to send email due to errors',
          'Incorrect password': 'Incorrect password',
          email_verified: 'Email is verified',
          email_not_verified: 'Email not verified',
          update_success: 'Successfully updated!',
          lang: 'English',
          email_expired: 'The verification email has expired',
          email_rate_limited:
            'Please send the email again after {{minutes}} minutes',
          email_already_exist: 'The email has already been registered.',
          duplicate_account_warning:
            'The same IP address can only have at most 3 accounts.',
          account_credits_title: 'Account Credits Balance',
          account_credits_sub_title: 'Current Credits',
          free_credits: 'Free Credits',
          refer_credits: 'Refer Credits',
          purchase_credits: 'Purchase Credits',
          total_credits: 'Total Credits',
          credits_channel: 'Credit Channels',
          free_channel:
            'Verified users get 10 initial free credits. When the free credits are below 10, automatically recharge to 10 credits for free every 30 days',
          refer_channel:
            'For each referred user who is verified, a reward of 50 referral points will be given',
          purchase_channel: 'Not yet available',
          credits_history: 'Credits Transaction History',
          diagnose_used_credits: 'Credits Used in AI Diagnose',
          chat_used_credits: 'Credits Used in Chat',
          price_header: 'Upgrade to Enjoy Unlimited Usages.',
          verify_email_reminder:
            'Automatically receive free credits every week after verifying your email',
          not_enough_credits:
            'Credits are insufficient, the user needs at least {{amount}} credits or Premium plan.',
          google_btn_label: 'Sign in with Google',
          date: 'date',
          type: 'type',
          weekly_refill: 'weekly/initial free credits',
          discord: 'Discord Community',
          diagnose_title: 'AI Diagnose',
          diagnose_dislaimer:
            'This product is not intended for use by members of the general public for medical diagnoses or other purposes. This product should only be used by healthcare providers, including physicians, clinicians, physician assistants, nurse practitioners, pharmacists, veterinary doctors, and clinicians in training.',
          diagnose_input_title: 'Symptoms Descriptions',
          diagnose_input_placeholder:
            'e.g. 73 year old man with a history of atrial fibrillation and cataracts presents with...',
          diagnose_vet_input_placeholder:
            'e.g. 5-year-old cat with a history of occasional vomiting and lethargy presents with...',
          copied: 'Copied!',
          diagnose_agreement:
            'By using our products, you attest that you are a healthcare provider and that you accept our <0>{{service_terms}}</0>.',
          set_private: 'Public Visible',
          set_public: 'Personal Visible',
          dashboard: 'Personal Library',
          updated_at: 'Updated {{date}}',
          updated: 'Updated',
          created_at: 'Created {{date}}',
          created: 'Created',
          private: 'PRIVATE',
          link_only: 'LINK ONLY',
          community: 'COMMUNITY',
          link_only_msg: 'Your page is accessible to anyone with the link.',
          community_msg:
            'Your page is accessible to everyone in the Community Library.',
          copy: 'COPY',
          copy_share_link: ' COPY SHARE LINK',
          community_title: 'Community Library',
          personal_title: 'Personal Library',
          community_title_warning:
            'Discover and save pages created by fellow members of the Medical Chat community. Please note that Medical Chat does not curate or endorse the content available in the Community Library.',
          no_results: 'There is no result',
          total_pages: '{{total}} pages',
          latest: 'latest',
          most_rated: 'most rated',
          most_saved: 'most saved',
          most_forked: 'most forked',
          cancel: 'CANCEL',
          confirm: 'CONFIRM',
          delete: 'DELETE',
          share: 'SHARE',
          fork: 'FORK',
          forks: '{{times}} forks',
          change_password_error:
            'New password has to be different from the old password',
          forked: 'FORKED',
          save: 'SAVE',
          saves: '{{times}} saves',
          likes: '{{times}} likes',
          saved: 'SAVED',
          lang_filter: 'Language Filter',
          edit: 'EDIT',
          search: 'Search',
          accurate: 'Accurate',
          inaccurate: 'Inaccurate',
          helpful: 'Helpful',
          not_helpful: 'Not Helpful',
          rate_plan_title: 'Rate this Clinical Plan',
          solicit_diagnosis_fb:
            'Please help us improve AI Diagnose by leaving specific feedback below.',
          additional_fb: 'Any additional comments?',
          create_case: 'CREATE CASE',
          print: 'PRINT',
          forked_from_msg: 'Forked from ',
          here: 'here',
          conern_msg:
            'Medical Chat does not curate or endorse Community Library content. Report concerning content below or send an email to admin@chat-data.com.',
          feedback_thankyou: 'Thanks! Your feedback is received!',
          submit_fb_btn: 'SUBMIT FEEDBACK',
          general_fb_label: 'Send us feedbacks',
          feature_1_title: 'Instant Answer',
          feature_1_content:
            'Receive immediate answers for any medical queries.',
          feature_2_title: 'Diagnosis Report',
          feature_2_content: 'Generate a Differential Diagnosis (DDx) report.',
          feature_3_title: 'Side by Side',
          feature_3_content:
            'Input one query and get multiple answers from different models side by side',
          faqs_title: 'FAQS',
          faqs_question_1:
            'Is my conversation history data stored by the website?',
          faqs_answer_1:
            'Rest assured, we highly prioritize your privacy. The conversation data is exclusively retained within your browser and not stored on our servers.',
          faqs_question_2: 'Which LLM models are available on this platform?',
          faqs_answer_2:
            'At present, our offerings encompass the proprietary Medical Chat model, in addition to ChatGPT 3.5 and ChatGPT 4.0. Anticipate the incorporation of further LLM models in subsequent phases.',
          faqs_question_3:
            ' Is the medical answer or diagnosis based on evidence?',
          faqs_answer_3:
            'Yes, all of our answers are based by either medical books or medical articles. We provide the original sources the end of each conversation message.',
          faqs_question_4: 'How does the Premium plan work?',
          faqs_answer_4:
            'The Premium plan offers subscribers a seamless user experience, providing unrestricted access to both the Medical Chat models and GPT 4.0 message services on our platform. Additionally, Premium users enjoy the added convenience of importing and exporting their conversations in JSON file format.',
          diagonsis_hint_1:
            'Effective <0>{{link}}</0>, also known as diagnostic one-liners, include relevant demographics, pertinent history or epidemiological risk factors, duration and tempo of the illness, as well as key signs and symptoms and key data (e.g., physical exam data, demographic information, patient discharge summaries). To improve the accuracy of Medical Chat, it is advisable to consolidate signs, symptoms, and test results into a description of a clinical syndrome and avoid using abbreviations. It should be noted that this application is not intended for gathering, processing, or analyzing medical images, signals from in vitro diagnostic devices, or patterns or signals from signal acquisition systems. Therefore, please refrain from submitting such information.',
          problem_presentation: 'problem representations',
          one_liner_symptoms: 'One-liner symptoms',
          video_warning: 'Your browser does not support the video tag.',
          final_warning: 'This cannot be undone.',
          delete_confirm: 'Are you sure you want to delete "{{title}}"?',
          final_dx: 'Final Diagnosis',
          title: 'Title',
          chat_like: 'Like',
          chat_dislike: 'Dislike',
          tutorial: 'Tutorial',
          proper_nouns_warning:
            'Feel free to ask your question in any language of your choice. However, please use English for proper nouns of medicines and diseases, as our model is trained on English content.',
          skip: 'Skip',
          next: 'Next',
          signin_msg: 'Please Sign In First to Take Actions',
          save_output: 'Save Clinical Plan To Case',
          human_medicine: 'Human Medical Mode',
          veterinary_medicine: 'Veterinary Medical Mode',
          veterinary_medicine_hint_content:
            'Click here to access the chatbot specifically trained to answer questions related to <0>veterinary medicine</0>.',
          veterinary_medicine_hint_title: '<0>Veterinary Medicine</0> Chatbot',
          human_medicine_hint_content:
            'Click here to access the chatbot specifically trained to answer questions related to <0>human medicine</0>.',
          human_medicine_hint_title: '<0>Human Medicine</0> Chatbot',
          log_warning: 'Please check this box first to {{action}}',
          save_hint_content:
            'Save your result so that you can modify, share, and update them based on your own needs.',
          diagonsis_hint_2:
            'The development of AI Diagnose, an experimental feature that employs an artificial intelligence (AI) model to generate a differential diagnosis or clinical plan from a diagnostic problem representation, is underway. The primary goal of this feature is to provide support or recommendations to healthcare providers regarding the prevention, diagnosis, or treatment of a disease or condition.',
          diagonsis_hint_3:
            'The field of AI is rapidly evolving, and there is constant research and testing being conducted. The AI used in Medical Chat is designed to produce a differential diagnosis or clinical plan based on the submitted diagnostic one-liner, regardless of its quality. It is important to note that the quality and usefulness of the output may vary greatly depending on the quality of the diagnostic one-liner submitted. Although measures have been taken to address this, there is a possibility that the AI output could be incomplete, incorrect, or biased based on the input provided. It is crucial to interpret all AI output with caution, as it does not replace the independent professional judgment of a healthcare provider. Healthcare providers should not solely rely on the AI output to make a clinical diagnosis or treatment decision for an individual patient.',
          diagnosis_chat_msg_loading: 'thinking ...',
          diagnosis_chat_subtitle:
            'Further questioning regarding the differential diagnosis report.',
          diagnosis_chat_sender_placeholder: 'Type a question...',
          diagnosis_chat_Welcome_msg:
            'What can I help you with the differential diagnosis report?',
          'medical-chat': 'Medical Chat',
          [process.env.NEXT_PUBLIC_GPT_4_MODEL]: 'GPT 4 Turbo',
          [process.env.NEXT_PUBLIC_GPT_4o_MODEL]: 'GPT 4o',
          'google-search': 'Google + GPT 3.5',
          [process.env.NEXT_PUBLIC_GPT_4o_MINI_MODEL]: 'GPT 4o Mini',
          [process.env.NEXT_PUBLIC_ANTHROPIC_OPUS_MODEL]: 'Claude 3 Opus',
          [process.env.NEXT_PUBLIC_ANTHROPIC_SONNET_MODEL]: 'Claude 3 Sonnet',
          [process.env.NEXT_PUBLIC_ANTHROPIC_HAIKU_MODEL]: 'Claude 3 Haiku',
          professional: 'Professional',
          premium: 'Premium',
        },
      },
      'cn-ZH': {
        translation: {
          cited_home_title: '引用的专业来源',
          professional: 'Professional',
          premium: 'Premium',
          cited_home_content:
            '我们模型的数据来源包括数千本专业医学教科书和先进医学文章。请放心，我们能为我们呈现给您的每一个知识点提供引用的来源。',
          nih_home_title: '患者普及',
          nih_home_content:
            '我们的模型提供了对PubMed文章的高级搜索功能，并能够提供与您想要查找的疾病或药物相关的最相关的NIH文章。',
          veterinary_home_title: '药品搜索',
          veterinary_home_content:
            '除了人类医学知识外，我们的医学助理还可以提供专业的兽医治疗方案，依据一个包含2000多本兽医书籍和10000多篇在线兽医文章的广泛数据库。',
          home_sub_title: '为治疗、患者教育和药物获取即时医疗答案',
          home_title: 'Medical Chat',
          home_title_2: '先进的{{target}}保健人工智能助理',
          'Human/Veterinary': '人类/兽医',
          price: '价格',
          chatbot: '智能聊天',
          diagnose_btn: '智能诊断',
          side_by_side: '多窗口聊天',
          community_btn: '社区',
          Products: '产品',
          Affiliate: '联盟营销',
          'Build Your Chatbot': '创建AI机器人',
          footer_disclaimer: `免责声明：我们的网站提供医疗聊天服务，但请注意，模型生成的回复是基于其训练时的信息，可能不总是准确、完整或最新的。回复仅供信息参考，旨在帮助医生进行日常诊断工作，而不是向患者提供医疗咨询。在做出任何关于您的健康或福祉的决定之前，请始终咨询合格的医疗保健提供者。我们不对使用我们的服务或模型提供的信息导致的任何损失、伤害或损害承担任何责任。通过使用我们的服务，您同意赔偿我们并使我们免受任何和所有索赔、要求和损害赔偿的损失，包括律师费，与您使用我们的服务有关。
                    我们也不编辑、背书、代表、支持或保证社区图书馆中发布的任何内容或通信的准确性、完整性或可靠性。在任何情况下，医疗聊天都不对使用本网站所导致的任何损失或损害，包括但不限于间接或后果性损失或损害承担责任。`,
          copyright: `{{date}}年© Medical Chat| 保留所有权利`,
          locale_all: '全部',
          locale_en: '英语',
          locale_cn: '中文',
          contact_email_msg_prefix: '需要帮助或支持，请给我们发送',
          contact_email_msg_suffix: '电子邮件',
          footer_privacy: '隐私政策',
          footer_service: ' 服务条款',
          Specialty: '专科领域',
          'Patient Education': ' 患者普及',
          'Drugs Information': ' 药品搜索',
          chat_footer: '先进的人类/兽医保健人工智能助理',
          chat_input_placehoder: '心血管疾病有哪些症状？',
          chat_vet_input_placehoder: '猫糖尿病的推荐治疗选项有哪些？',
          'Example: Specialty': '专科领域问题示范<0>{{nouns_warning}}</0>',
          'Example: Veterinary': '动物医学问题示范<0>{{nouns_warning}}</0>',
          nouns_warning: '(专有名词请使用英文)',
          specialty_example_question_1:
            '什么是carbidopa-levodopa enteral suspension，它有什么用途？',
          specialty_example_question_2: '使用LCIG会有哪些潜在风险或副作用？',
          specialty_example_question_3:
            ' self-antigen和foreign antigen有什么区别？',
          specialty_example_question_4:
            'autoimmune diseases的一些常见治疗方法是什么？',
          specialty_example_question_5:
            '什么是autoimmune diseases，它与其他免疫反应有什么不同？',
          specialty_example_question_6: '哪些机械因素可能导致孕期背痛？',
          specialty_example_question_7: '孕期背痛可以预防吗？',
          specialty_example_question_8:
            'septic thrombophlebitis的并发症有哪些？',
          specialty_example_question_9:
            '对于导管相关性septic thrombophlebitis，应该继续多长时间的抗生素治疗？',
          specialty_example_question_10:
            '导致儿童和青少年膝关节受伤的最常见活动有哪些？',
          specialty_example_question_11: '诊断急性膝关节损伤使用哪些诊断工具？',
          specialty_example_question_12:
            '间歇性感觉皮肤瘙痒是不是因为生病了？有什么阻止措施？',
          vet_example_question_1: '猫糖尿病的推荐治疗选项是什么？',
          vet_example_question_2: '常用于缓解马腹痛的药物或治疗方法有哪些？',
          vet_example_question_3: '对于犬心丝虫病，什么是最有效的治疗方案？',
          vet_example_question_4: '兽医针灸如何帮助治疗犬类的肌肉骨骼疾病？',
          vet_example_question_5:
            '对于患有慢性呼吸道感染的鸟类，Respireze的推荐剂量和使用方法是什么？',
          vet_example_question_6:
            '当给患有贫血的雪貂开处方使用HemoVet时，是否需要考虑任何药物相互作用或禁忌症？',
          vet_example_question_7:
            'GastroGuard Plus是如何治疗赛马胃溃疡的？它有哪些特定的治疗靶点？',
          vet_example_question_8:
            '肾护灵（RenalCare）在慢性肾脏病患犬中的推荐剂量和用法是什么？',
          vet_example_question_9:
            '对于幼犬来说，有哪些必要的疫苗接种和预防药物推荐？它们应该在什么年龄开始进行疫苗接种计划？',
          vet_example_question_10:
            '豚鼠患有牙齿疾病的征兆有哪些？如何通过适当的饮食和咀嚼材料来支持它们的牙齿健康？',
          vet_example_question_11:
            '在为宠物乌龟创建一个安全的室外空间时，包括围场设计、阳光暴露以及防护措施等方面需要考虑哪些重要因素？',
          vet_example_question_12:
            '在家中已有其他猫的情况下，引进新的小猫应该采取哪些合适的步骤，以及如何促进顺利过渡？',
          'Example: Patient Education': '患者普及问题示范',
          education_example_question_1: '什么是骨髓移植？',
          education_example_question_2: '如何找到干细胞供体？',
          education_example_question_3: 'hairy cell leukemia有哪些症状？',
          education_example_question_4: 'hairy cell leukemia的治疗方法是什么？',
          education_example_question_5: '我有间歇性头疼，可能是什么原因？',
          education_example_question_6: 'sickle cell trait的症状是什么？',
          education_example_question_7: '失眠症的症状是什么？',
          education_example_question_8: '失眠症的治疗方法是什么？',
          education_example_question_9: '睡眠不足会导致什么问题？',
          education_example_question_10: '什么会导致adrenal crisis？',
          education_example_question_11: 'adrenal crisis可以预防吗？',
          education_example_question_12: 'hepatic encephalopathy有检测方法吗？',
          'Example: Drugs Information': '药品搜索问题示范',
          drugs_example_question_1: '什么是Abaloparatide，可能有哪些副作用？',
          drugs_example_question_2: '注射Abaloparatide时可能出现哪些局部反应？',
          drugs_example_question_3: '使用Abaloparatide时应当评估哪些监测参数？',
          drugs_example_question_4: 'Acarbose有哪些常见的不良反应？',
          drugs_example_question_5:
            '为什么Acarbose对于患有炎症性肠病和结肠溃疡的患者是禁忌的？',
          drugs_example_question_6:
            '由于Acarbose引起的肠道气体增多，可能导致哪些病情恶化？',
          drugs_example_question_7: 'Acarbose会如何影响Digoxin？',
          drugs_example_question_8:
            'Abemaciclib如何使用，使用时有哪些推荐的注意事项？',
          drugs_example_question_9:
            'Abemaciclib有哪些常见的不良反应，其发生率是多少？',
          drugs_example_question_10: 'Abemaciclib有哪些与肾脏有关的不良反应？',
          drugs_example_question_11:
            '使用Abemaciclib有哪些禁忌症？如果有的话，它们是什么？',
          drugs_example_question_12:
            '对于肾功能严重受损的患者，Acetazolamide是否禁忌？',
          code_type_wrong: '验证码类型错误',
          others_code: '验证码属于其他用户',
          limitation: '限制',
          limitation_1:
            '这些信息旨在为医生服务，帮助他们进行日常的诊断工作，而非面向患者。',
          limitation_2:
            '提供的信息应该被用作帮助用户了解潜在的诊断和治疗选择的工具',
          limitation_3:
            '该信息可能未涵盖与患者的具体情况有关的所有疾病、治疗、药物、副作用或风险的详细信息',
          limitation_4:
            '提供的信息并不能替代医疗专业人员对患者独特情况进行检查和评估后的医疗建议、诊断或治疗',
          limitation_5:
            '患者必须咨询医疗保健提供者以获得关于其健康、医疗咨询和治疗选择的完整信息，包括使用药物的任何风险或益处',
          limitation_6:
            '提供的信息不支持任何治疗或药物被视为对特定患者的治疗绝对安全、有效',
          chat_expand: '拓展',
          chat_ref: 'PubMed相关文献',
          sign_up_title: '用户注册',
          user_name: '用户名',
          email: '电子邮箱',
          password: '密码',
          'confirm password': '确认密码',
          submit: '提交',
          ack: '知道了',
          homescreen_share_hint:
            '将其添加到您的主屏幕，获得类似手机App的体验。',
          sign_in_reminder: '已经注册过了？',
          sign_in: '登入',
          sign_in_failure: '登入失败',
          sign_up: '注册',
          sign_out: '退出登入',
          password_different: '两次密码不一样',
          password_restriction: '你的密码必须至少6位',
          sign_in_title: '用户登入',
          forget_password_reminder: '忘记密码？',
          sign_up_reminder: '需要账号？',
          reset_password_title: '重置密码',
          reset_password_sent: '重置密码已发送，请检查您的邮箱！(24小时后失效)',
          reset_password_failure: '重置密码失败',
          reset_password_success: '密码重置成功，请重新登入',
          nav_profile: '账号设置',
          loading_msg: '加载中...',
          setting_title: '账户',
          setting_sub_title: '账户更新',
          current_password: '当前密码',
          new_password: '新密码',
          new_password_confirm: '再次输入新密码',
          refer_link: '推荐连接',
          setting_password_placeholder: '留空保持不变',
          current_password_placeholder: '需输入当前密码才能更改密码',
          change_password_error: '新密码和旧密码必须不同',
          verify_email: '验证邮箱',
          email_sent_success: '已发送邮件',
          email_sent_failure: '邮件发送失败',
          'Incorrect password': '密码不正确',
          email_verified: '邮箱已验证',
          email_not_verified: '邮箱未验证',
          email_verification_success: '验证邮箱成功',
          email_verification_failure: '验证邮箱失败',
          update_success: '更新成功!',
          lang: 'Chinese',
          email_expired: '验证邮件已过期',
          email_rate_limited: '请{{minutes}}分钟后再次发送邮件',
          email_already_exist: '邮箱已注册',
          duplicate_account_warning: '相同的IP地址最多只能拥有3个账户。',
          account_credits_title: '账户积分余额',
          account_credits_sub_title: '当前积分',
          free_credits: '免费积分',
          refer_credits: '推荐积分',
          purchase_credits: '购买积分',
          total_credits: '总积分',
          credits_channel: '获取渠道',
          free_channel:
            '验证用户获得30初始免费积分，当免费积分10以下时： 每30天自动免费充满至10积分',
          refer_channel: '每推荐一个被验证的用户，奖励50推荐积分',
          purchase_channel: '暂未开通',
          credits_history: '积分历史流水',
          price_header: '升级享受无限使用。',
          verify_email_reminder: '验证邮箱后每周自动获得免费积分',
          not_enough_credits:
            '账户积分不够, 用户需要至少 {{amount}} 积分或者Premium会员。',
          google_btn_label: '使用谷歌账户登入',
          date: '日期',
          type: '类型',
          rate_plan_title: '评价这个诊断报告',
          solicit_diagnosis_fb:
            '请在下方留下具体反馈，帮助我们改进AI Diagnose。',
          additional_fb: '其他评论？',
          weekly_refill: '初始/每周 免费积分',
          diagnose_used_credits: 'AI Diagnose消耗',
          chat_used_credits: '聊天消耗',
          discord: 'Discord客服',
          diagnose_title: 'AI Diagnose',
          diagnose_dislaimer:
            '该产品不适用于普通公众进行医疗诊断或其他用途。该产品只应由医疗保健提供者，包括医生、临床医师、医师助理、护理师、药剂师和临床实习医师使用。',
          diagnose_input_title: '症状描述',
          diagnose_input_placeholder:
            '例如，一名患有心房颤动和白内障病史的73岁男子来就诊，症状为...',
          diagnose_vet_input_placeholder:
            '例如，一只5岁大、有时呕吐和无精打采病史的猫，症状为...',
          copied: '已拷贝!',
          diagnose_agreement:
            '使用我们的产品前，请确保您是一名医疗保健提供者，并且您接受我们的<0>{{service_terms}}</0>',
          set_private: '个人可见',
          set_public: '公众可见',
          dashboard: '个人资料库',
          updated_at: '更新于{{date}}',
          updated: '更新于',
          created_at: '创建于{{date}}',
          created: '创建于',
          private: '私密',
          link_only: '仅链接',
          community: '社群',
          create_case: '创建病例',
          link_only_msg: '您的页面可以通过链接被任何人访问。',
          community_msg: '您的页面可以被社群中的所有人访问。',
          copy_share_link: '复制分享链接',
          community_title: '社区图书馆',
          community_title_warning:
            '搜索并保存由Medical Chat社区其他成员创建的页面。请注意，Medical Chat不会筛选或认可社区图书馆中的内容。',
          no_results: '没有结果',
          total_pages: '{{total}} 页',
          latest: '最新',
          copy: '复制',
          accurate: '准确',
          inaccurate: '不准确',
          helpful: '有用',
          not_helpful: '没有用',
          most_rated: '最受欢迎',
          most_saved: '被保存最多',
          most_forked: '被复制最多',
          cancel: '取消',
          confirm: '确定',
          delete: '删除',
          share: '分享',
          fork: '分叉',
          forks: '{{times}}次分叉',
          forked: '已分叉',
          save: '保存',
          saves: '{{times}}次保存',
          likes: '{{times}}次点赞',
          saved: '已保存',
          lang_filter: '语言筛选',
          edit: '编辑',
          search: '搜索',
          print: '打印',
          forked_from_msg: '分叉来源于',
          here: '此处',
          conern_msg:
            'Medical Chat不筛选或认可社区图书馆的内容。请在下方报告有关内容，或发送电子邮件至admin@chat-data.com。',
          feedback_thankyou: '谢谢！已收到您的反馈！',
          submit_fb_btn: '提交反馈',
          general_fb_label: '给我们发送反馈',
          feature_1_title: '即时回答',
          feature_1_content: '立即获得任何医疗查询的答案。',
          feature_2_title: '诊断报告',
          feature_2_content:
            '从症状生成全面的鉴别诊断（DDx）报告，并与聊天机器人无缝互动以进行后续询问。',
          feature_3_title: '多窗口聊天',
          feature_3_content:
            '输入一个查询，并从多窗口的不同模型中获得多个答案。',
          faqs_title: '常见问题解答',
          faqs_question_1: '我的对话历史数据会被网站存储吗？',
          faqs_answer_1:
            '请放心，我们高度重视您的隐私。对话数据仅在您的浏览器内保留，不会存储在我们的服务器上。',
          faqs_question_2: '这个平台提供哪些LLM模型？',
          faqs_answer_2:
            '目前，我们提供专有的Medical Chat模型，以及ChatGPT 3.5和ChatGPT 4.0。预计将在后续阶段引入更多的LLM模型。',
          faqs_question_3: '医学回答或诊断是否基于证据？',
          faqs_answer_3:
            '是的，我们所有的答案都基于医学书籍或医学文章。我们会在每条对话消息的末尾提供原始来源。',
          faqs_question_4: 'Premium Plan是如何运作的？',
          faqs_answer_4:
            'Premium Plan为订阅用户提供无缝的用户体验，在我们的平台上提供对医学聊天模型和 GPT 4.0 消息服务的不受限制的访问。此外，优质用户还可以方便地导入和导出他们的对话，文件格式为 JSON。',
          diagonsis_hint_1:
            '有效的<0>{{link}}</0>，也称为症状简述，包括相关的人口统计信息、相关的病史或流行病学危险因素、疾病的持续时间和速度，以及关键的体征和症状和关键数据（例如，体检数据、人口统计信息、患者出院小结）。为了提高医学聊天的准确性，建议将体征、症状和检查结果整合成一个临床综合症的描述，避免使用缩写。请注意，该应用程序不适用于收集、处理或分析医学图像、体外诊断设备信号或信号采集系统的模式或信号。因此，请勿提交此类信息。',
          problem_presentation: '问题陈述',
          one_liner_symptoms: '症状简述',
          video_warning: '您的浏览器不支持 video 标签',
          save_output: '将诊断报告保存到病例中',
          final_warning: '该操作无法撤销。',
          title: '标题',
          chat_like: '喜欢',
          chat_dislike: '不喜欢',
          signin_msg: '请先登录以执行操作',
          delete_confirm: '你确定要删除"{{title}}"吗？',
          final_dx: '最终诊断',
          tutorial: '教程',
          proper_nouns_warning:
            '请随意使用您喜欢的任何语言提出问题。但是，请在涉及药品和疾病的专有名词时使用英语，因为我们的模型是使用英语内容训练的。',
          skip: '跳过教程',
          next: '下一步',
          human_medicine: '人类医学模式',
          veterinary_medicine: '动物医学模式',
          'The underlying configuration is set up to address the human medical inqueries.':
            '底层配置已设置为处理人类医疗问题。',
          'The underlying configuration is set up to address the veterinary medicine inqueries.':
            '底层配置已设置为处理兽医医疗问题。',
          'Mode Selection': '模式选择',
          veterinary_medicine_hint_content:
            '点击这里访问专门针对<0>动物医学</0>问题回答的聊天机器人。',
          veterinary_medicine_hint_title: '<0>动物医学</0>聊天机器人',
          human_medicine_hint_content:
            '点击这里访问专门针对<0>人类医学</0>问题回答的聊天机器人。',
          human_medicine_hint_title: '<0>人类医学</0>聊天机器人',
          save_hint_content:
            '保存您的诊断报告，以便根据自己的需求进行修改、共享和更新。',
          log_warning: '请先勾选此框以进行{{action}}。',
          diagonsis_hint_2:
            '正在开发的AI Diagnose，一种实验性功能，它使用人工智能（AI）模型从诊断问题描述中生成诊断报告。该功能的主要目标是向医疗保健从业人员提供有关疾病或状况的预防、诊断或治疗方面的支持或建议。',
          diagonsis_hint_3:
            'AI领域正在迅速发展，不断进行研究和测试。Medical Chat中使用的AI旨在基于提交的症状简述，生成诊断报告，而与其质量无关。需要注意的是，输出的质量和有用性可能会因提交的症状简述质量的不同而有很大差异。尽管已经采取措施来解决这个问题，但AI输出可能会因提供的输入不完整、不正确或带有偏见。解释所有AI输出时要非常谨慎，因为它不能替代医疗保健从业人员独立的专业判断。医疗保健从业人员不应仅依赖AI输出为单个患者做出临床诊断或治疗决策。',
          diagnosis_chat_msg_loading: '思考中 ...',
          diagnosis_chat_subtitle: '对诊断报告进一步提问',
          diagnosis_chat_sender_placeholder: '输入问题...',
          diagnosis_chat_Welcome_msg:
            '有什么关于诊断报告的问题我可以帮您解答吗？',
          'medical-chat': 'Medical Chat',
          [process.env.NEXT_PUBLIC_GPT_4_MODEL]: 'GPT 4 Turbo',
          [process.env.NEXT_PUBLIC_GPT_4o_MODEL]: 'GPT 4o',
          'google-search': 'Google + GPT 3.5',
          [process.env.NEXT_PUBLIC_GPT_4o_MINI_MODEL]: 'GPT 4o Mini',
          [process.env.NEXT_PUBLIC_ANTHROPIC_OPUS_MODEL]: 'Claude 3 Opus',
          [process.env.NEXT_PUBLIC_ANTHROPIC_SONNET_MODEL]: 'Claude 3 Sonnet',
          [process.env.NEXT_PUBLIC_ANTHROPIC_HAIKU_MODEL]: 'Claude 3 Haiku',
          'Copy code': '复制代码',
          'Copied!': '已复制！',
          'Enter file name': '输入文件名',
          'New prompt': '新建提示',
          'New folder': '新建文件夹',
          'No prompts.': '无提示词',
          'Search prompts...': '搜索提示...',
          Name: '名称',
          'A name for your prompt.': '提示词名称',
          Description: '描述',
          'A description for your prompt.': '提示词描述',
          Prompt: '提示词',
          'Prompt content. Use {{}} to denote a variable. Ex: {{name}} is a {{adjective}} {{noun}}':
            '提示内容。使用 {{}} 表示一个变量。例如：{{name}} 是一个 {{adjective}} {{noun}}',
          Save: '保存',
          Settings: '设置',
          Theme: '主题',
          'Dark mode': '深色模式',
          'Light mode': '浅色模式',
          Save: '保存',
          'New folder': '新建文件夹',
          'New chat': '新建聊天',
          'New Conversation': '新的聊天',
          'No conversations.': '无对话',
          'Search conversations...': '搜索对话...',
          'OpenAI API Key': 'OpenAI API 密钥',
          'Import data': '导入对话',
          'Are you sure?': '确定吗？',
          'Clear conversations': '清空对话',
          Settings: '设置',
          'Export data': '导出对话',
          'Plugin Keys': '插件密钥',
          'OpenAI API Key Required': '需要 OpenAI API 密钥',
          'Please set your OpenAI API key in the bottom left of the sidebar.':
            '请在侧边栏左下角设置您的 OpenAI API 密钥。',
          "If you don't have an OpenAI API key, you can get one here: ":
            '如果你没有 OpenAI API 密钥，你可以在此获取：',
          'Stop Generating': '停止生成',
          'Prompt limit is {{maxLength}} characters':
            '提示字数限制为 {{maxLength}} 个字符',
          'New Conversation': '新的聊天',
          'System Prompt': '系统提示',
          "You are ChatGPT, a large language model trained by OpenAI. Follow the user's instructions carefully. Respond using markdown.":
            '你是 ChatGPT，一个由 OpenAI 训练的大型语言模型。请仔细遵循用户的指示。使用 Markdown 格式进行回应。',
          'Enter a prompt': '输入一个提示',
          'Regenerate response': '重新生成回应',
          'Sorry, there was an error.': '抱歉，出现了错误。',
          Model: '模型',
          Conversation: '对话',
          OR: '或',
          'Loading...': '加载中...',
          'Type a message or type "/" to select a prompt...':
            '输入一条消息或键入 "/" 以选择提示...',
          'Error fetching models.': '获取模型时出错。',
          AI: 'AI',
          You: '你',
          Cancel: '取消',
          'Save & Submit': '保存并提交',
          'Make sure your OpenAI API key is set in the bottom left of the sidebar.':
            '请确保您的 OpenAI API 密钥已在侧边栏左下角设置。',
          'If you completed this step, OpenAI may be experiencing issues.':
            '如果您已完成此步骤，OpenAI 可能遇到了问题。',
          'click if using a .env.local file':
            'click if using a .env.local file',
          'Message limit is {{maxLength}} characters. You have entered {{valueLength}} characters.':
            '消息字数限制为 {{maxLength}} 个字符。您已输入 {{valueLength}} 个字符。',
          'Please enter a message': '请输入一条消息',
          "Chatbot UI is an advanced chatbot kit for OpenAI's chat models aiming to mimic ChatGPT's interface and functionality.":
            'Chatbot UI 是一个高级聊天机器人工具包，旨在模仿 OpenAI 聊天模型的 ChatGPT 界面和功能。',
          'Are you sure you want to clear all messages?':
            '你确定要清除所有的消息吗？',
          'Higher values like 0.8 will make the output more random, while lower values like 0.2 will make it more focused and deterministic.':
            '较高的数值（例如0.8）会使输出更随机，而较低的数值（例如0.2）会使输出更加聚焦和确定性更强。',
          'View Account Usage': '查阅账户用量',
          Temperature: '模型温度',
          'System Instructions': '系统指令',
          "Customize the AI's behavior with specific instructions":
            '使用特定指令自定义AI的行为',
          Precise: '保守',
          Neutral: '中立',
          Creative: '开放',
          Home: '主页',
          'Follow up questions you might want to ask': '你可能想要问的后续问题',
          Premium: '仅限付费用户',
          'Effortlessly export and import conversation history':
            '轻松导出和导入对话历史记录',
          'Efficiently query suggested follow-up questions and search PubMed libraries':
            '高效查询建议的后续问题并搜索PubMed文库',
          'Create and save custom prompt templates to eliminate manual typing':
            '创建并保存自定义提示模板以消除手动输入',
          'Seamlessly switch between different LLM models':
            '在不同的LLM模型之间无缝切换',
          'Caters to both human medicine and veterinary medicine modes':
            '适用于人类医学和兽医学模式',
          'Create patient-specific clinic plans, including differential diagnosis, patient education, and medication recommendations':
            '创建针对患者的诊疗计划，包括差异诊断、患者教育和药物建议',
          'Effortlessly save, edit, and share clinic plans via private links or within the community':
            '轻松保存、编辑和通过私人链接或社区内共享诊疗计划',
          'Develop your personal clinic plans based on the clinic plans shared within the community':
            '根据社区共享的诊所计划，制定您个人的诊所计划',
          'Frequently asked questions': '常见问题',
          'Can I integrate the Medical Chat models into my platform?':
            '我能把医疗聊天模型集成到我的平台吗？',
          'You can incorporate the Medical Chat models into your platform by creating a chatbot on the Chat Data platform (http://chat-data.com) and choosing either the medical-chat-human or medical-chat-vet models.':
            '你可以通过在Chat Data平台（http://chat-data.com）上创建一个聊天机器人，并选择医疗聊天人类或医疗聊天兽医模型，将医疗聊天模型整合到你的平台中。',
          'How can I cancel the Premium plan?': '怎么取消Premium plan？',
          'To cancel your subscription with Medical Chat, you have the option to downgrade your account at any time through the customer portal provided by Stripe, the platform through which you initially subscribed to the Premium plan. Alternatively, you can directly contact our support team via email at admin@chat-data.com, and they will assist you promptly, typically responding within 12 hours.':
            '取消与Medical Chat的订阅，您可以随时通过Stripe提供的客户端口取消你的Premium Plan，或者，您可以直接通过电子邮件与我们的客服联系，邮箱地址是admin@chat-data.com，他们将迅速协助您，通常在12小时内回复',
          'Does Medical Chat offer app in IOS or Android?':
            'Medical Chat在IOS或Android平台上提供应用程序吗？',
          'Currently, we do not have an iOS or Android app available in the app store. However, you can follow these two instructions (iOS: https://www.guidingtech.com/guide-to-using-web-apps-on-iphone-and-ipad and Android: https://beebom.com/ways-turn-any-website-android-app) to save our website as a web app on your phone screen and use it similarly to an app on your phone.':
            '目前我们在应用商店没有iOS或Android应用。然而，您可以按照以下两个步骤（iOS: https://www.guidingtech.com/guide-to-using-web-apps-on-iphone-and-ipad 和 Android: https://beebom.com/ways-turn-any-website-android-app ）将我们的网站保存为在您手机屏幕上的Web应用程序，并类似于在手机上使用应用程序。',
          "Why can't I sign up?": '为什么我不能注册？',
          'The issue may be that another email has been used to sign up with the same device in the past. To prevent abuse of free credits accounts, we only allow premium plan accounts to log in with the same device. A free account can be logged in with a single email per device.':
            '问题可能是过去曾经使用另一个电子邮件地址在同一设备上注册。为防止滥用免费积分账户，我们只允许高级计划账户使用同一设备登录。每个设备只能使用一个电子邮件登录免费账户。',
          'Is there a free plan in Medical Chat?':
            'Medical Chat是否有免费计划？',
          'Yes, all registered accounts will automatically receive 10 free credits at the beginning of each month. This allows free plan users to ask questions without any hassle. It\'s important to note that these free message credits follow a "use it or lose it" mechanism.':
            '是的，每个注册账户在每个月初都会自动获得10个免费积分。这使得免费计划用户可以毫不费力地提出问题。重要的是要注意，这些免费的消息积分采取“用了就没了”的机制。',
          "Can I upload a doctor's prescription?": '我可以上传医生的处方吗？',
          "Yes, you can utilize the PDF uploading feature to upload a doctor's prescription and ask related questions about it. It's important to note that we do not save your data on our server.":
            '是的，您可以使用PDF上传功能上传医生的处方，并提出相关问题。我们不会在服务器上保存您的数据。',
          'Receive a complimentary two-month subscription when you opt for an annual plan!':
            '在选择年度计划时，您将获赠两个月的免费订阅！',
          Monthly: '月度计划',
          Annually: '年度计划',
          'The unlocked plan provides unlimited full access to all features.':
            '提供对所有功能的无限完全访问。',
          'The initial plan for exploring the product.': '探索该产品',
          '/month': '/月',
          '/year': '/年',
          'Limited Access based on Credits': '有限访问，基于积分',
          'Access to Medical Chat Chatbot': '访问医疗聊天机器人',
          'Access to ChatGPT 3.5 Chatbot': '访问CHATGPT 3.5聊天机器人',
          'Access to AI Diagnose': '访问AI诊断',
          '6 Message Credits per Month': '每月6积分',
          'Unlimited Access to Medical Chat Chatbot': '无限访问医疗聊天机器人',
          'Unlimited Access to ChatGPT 3.5 Chatbot':
            '无限访问ChatGPT 3.5聊天机器人',
          'Unlimited Access to ChatGPT 4.0 Chatbot':
            '访问ChatGPT 4.0聊天机器人',
          'Unlimited Access to AI Diagnose': '无限访问AI诊断',
          'Import & Export data': '导入导出聊天数据',
          'Get Started': '开始',
          Upgrade: '升级',
          Unlocked: '已解锁',
          'Please Sign In First to Take Any Action': '请先登录以执行任何操作',
          Confirm: '确定',
          'Remember me': '记住我',
          'Forgot password?': '忘记密码？',
          'Your current message credits are': '你目前的积分余额是',
          'Your credits renew at the start of every calendar month. Next renewal:':
            '您的积分额度在每个月初都会更新。下次更新：',
          'Please use a valid email': '请使用有效的Email',
          Refresh: '刷新',
          'Generate the DDX report': '生成鉴别诊断报告',
          'Start Fresh': '重新开始',
          'Human Medical bot': '人类医学问题机器人',
          'Animal Medical bot': '动物医学问题机器人',
          'Animal Medical bot': '动物医学问题机器人',
          'Animal Medical bot': '动物医学问题机器人',
          'THIS IS IRREVERSIBLE!': '不可逆！',
          'Confirm that you no longer want to see the examples.':
            '确认取消问题示范',
          Resources: '资料',
          Roadmap: '未来计划',
          'Message Credits': '积分',
          'Select Model': '选择模型',
          'AI Diagnose is not designed for conversations or free-style tasks. Its purpose is to generate a DDX report in a specific format. Please provide symptoms, gender, age, and patient descriptions only.':
            'AI Diagnose不适用自由模式。其目的是按照特定格式生成DDX报告。请仅提供症状、性别、年龄和患者描述。',
        },
      },
    },
    fallbackLng: 'en-US',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
