// pages/_app.js
import React from 'react';
import PageWrapper from '@/components/wrapper/PageWrapper';
import '@/styles/globals.css';
import '@/styles/override.css';

// Initialize Google Analytics outside of the functional component

// const preventIframeEmbedding = () => {
//   if (window.self !== window.top) {
//     document.body.innerHTML = 'This website cannot be embedded.';
//   }
// };

function MyApp({ Component, pageProps }) {
  return (
    <>
      <PageWrapper>
        <Component {...pageProps} />
      </PageWrapper>
    </>
  );
}

export default MyApp;
