import React from 'react';
import { I18nextProvider } from 'react-i18next';
import i18n from '../../contexts/i18n';

const PageWrapper = ({ children }) => {
  // Customize your i18nData and AuthProvider settings here if needed

  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
};

export default PageWrapper;
